import React, { Suspense } from 'react'
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { renderRoutes } from 'react-router-config';
import { Grow, CircularProgress, Backdrop, Grid } from '@mui/material';
import routes from './routes';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import lightTheme from 'themes/mui-lightTheme';
import darkTheme from 'themes/mui-darkTheme';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const history = createBrowserHistory();
  //require('./themes//light.css');
  //require('./themes//dark2.css');
  require('./index.css');
  const [lightMode, setLightMode] = React.useState(true);

  React.useEffect(() => {
    const _mode = window.localStorage.getItem('lsf-theme-mode');
    if (_mode) {
      if (_mode === 'dark') {
        setLightMode(false);
      }
      else {
        setLightMode(true);
      }
    }
  }, []);

  return (
    <Suspense
      fallback={
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" size={50} />
        </Backdrop>
      }>
      <SnackbarProvider maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Grow}
        dense>
        <GoogleOAuthProvider clientId='555062631596-jsc7bt9g99upf4qf60pekde7tbrk3v97.apps.googleusercontent.com'>
          <ThemeProvider theme={lightMode ? lightTheme : darkTheme}>
            <CssBaseline />
            <Router history={history}>
              {renderRoutes(routes)}
            </Router>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
