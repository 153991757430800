import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        light: '#63a4ff',
        main: '#1976d2',
        dark: '#004ba0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff5f52',
        main: '#c62828',
        dark: '#8e0000',
        contrastText: '#fff',
      },
      success: {
        light: '#60ad5e',
        main: '#2e7d32',
        dark: '#005005',
        contrastText: '#fff',
      },
      warning: {
        light: '#ffd95a',
        main: '#f9a825',
        dark: '#c17900',
        contrastText: '#fff',
      },
      info: {
        light: '#4fc3f7',
        main: '#29b6f6',
        dark: '#0288d1',
        contrastText: '#fff',
      }
    },
    typography: {
        fontFamily: [
            'Itim-Regular'
        ],
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    color: 'white'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#181414',
                    color: 'white'
                }
            }
        }
    }
  });

export default lightTheme;