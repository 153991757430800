import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

export default [
    {
        path: '/',
        exact: true,
        component: lazy(() =>
            import('./screens/home')
        ),
    },
    {
        path: '/PrivacyPolicy',
        exact: true,
        component: lazy(() =>
            import('./screens/privacy')
        ),
    },
    {
        path: '/Login',
        exact: true,
        component: lazy(() =>
            import('./screens/login2')
        ),
    },
    {
        path: '/Readers/Read/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/reader/read')
        ),
    },
    {
        path: '/MyProfile',
        exact: true,
        component: lazy(() =>
            import('./screens/myprofile')
        ),
    },
    {
        path: '/Register',
        exact: true,
        component: lazy(() =>
            import('./screens/register')
        ),
    },
    {
        path: '/NovelRead/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/novel')
        ),
    },
    {
        path: '/MangaRead/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/manga')
        ),
    },
    {
        path: '/Identity/Account/SpecialLogin/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/specialLogin')
        ),
    },
    {
        path: '/Administator',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/index')
        ),
    },
    {
        path: '/Administator/ManageNovel',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/manageNovel')
        ),
    },
    {
        path: '/Translator',
        exact: true,
        component: lazy(() =>
            import('./screens/translator/index')
        ),
    },
    {
        path: '/Administator/Novel/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/novelEdit')
        ),
    },
    {
        path: '/Translator/Chapters/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/translator/chapterList')
        ),
    },
    {
        path: '/Translator/Chapters/Update/:refNovelId/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/translator/chapterEdit')
        ),
    },
    {
        path: '/Translator/Chapters/Create/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/translator/chapterNew')
        ),
    },
    {
        path: '/MyTest',
        exact: true,
        component: lazy(() =>
            import('./screens/myTest')
        ),
    },
    {
        path: '/Error/NotFound',
        exact: true,
        component: lazy(() =>
            import('./screens/errors/error-404')
        ),
    },
    {
        path: '/Error/Unauthorized',
        exact: true,
        component: lazy(() =>
            import('./screens/errors/error-401')
        ),
    },
    {
        path: '/Error/UserDeleted',
        exact: true,
        component: lazy(() =>
            import('./screens/errors/error-user-deleted')
        ),
    },
    {
        path: '/Admin/UserTransaction/:refId',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/transaction')
        ),
    },
    {
        path: '/Admin/ManageManga',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/manageManga')
        ),
    },
    {
        path: '/Admin/ManageManga/:mangaId/Chapter',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/mangaChapter')
        ),
    },
    {
        path: '/Admin/ManageManga/:mangaId/Chapter/Add',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/mangaAddEdit')
        ),
    },
    {
        path: '/Admin/ManageManga/:mangaId/Chapter/:chapterId',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/mangaAddEdit')
        ),
    },
    {
        path: '/Readers/MangaRead/:mangaChapterId',
        exact: true,
        component: lazy(() =>
            import('./screens/reader/mangaRead')
        ),
    },
    {
        path: '/Admin/Configuration',
        exact: true,
        component: lazy(() =>
            import('./screens/admin/configuration')
        ),
    },
    {
        path: '/CreateAccount',
        exact: true,
        component: lazy(() =>
            import('./screens/user/createAccount')
        ),
    },
    {
        component: lazy(() =>
            import('./screens/errors/error-404')
        ),
    },
]


